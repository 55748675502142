import React from 'react'
import "../Style/ReturnPolicy.css";
import { Helmet } from 'react-helmet';

const ReturnPolicy = () => {
  const domain=process.env.REACT_APP_DOMAIN;

  return (
    <>
       {/* Meta Section */}
       <Helmet>
        <meta charSet="utf-8" />
        <title>Return Policy | Flonix Technology </title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href={`${domain}/ReturnPolicy`}
        />
      </Helmet>
    <section  className="" style={{margin:"50px 0"}}>
      <div className="">
        <div className="main-width">
          <div>
            <div className="under1320width">
              <div className="all-page-padding">
                <div className='return-policy-bg'>
                    <div className='background-text-privacy-policy'>
                    <p className='privacy-policy-banner-heading'>Cancellation and Return Policy</p>
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
      <section  className="" style={{margin:"50px 0"}} >
      <div className="">
        <div className="main-width">
          <div>
            <div className="under1320width">
              <div className="all-page-padding">
                
                    <div className='all-heading-and-pera'>
                    <p className='privacy-policy-peragraph'>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. www.flonix.com from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. <br />
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. <br />
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. <br />
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." <br />
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    </p>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}

export default ReturnPolicy